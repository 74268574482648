import { Injectable } from '@angular/core';
import { UserService } from '../../user/user.service';
import { environment } from '@environments/environment';

declare var _learnq: any;

export const MAIN_NAVIGATION_CHANGE = 'Main Navigation';

@Injectable({
  providedIn: 'root'
})
export class KlaviyoService {
  constructor(private _userService: UserService) {
  }

  public sendMetric(metricName: string, payload: Object) {
    if (environment.name !== 'dev' && typeof _learnq !== 'undefined' && _learnq) {
      const userDate = new Date().toString();
      payload['userDate'] = userDate;
      payload['environment'] = environment.name;
      _learnq.push(['track', metricName, payload]);
    }
  }

  public initializeCurrentUser() {
    if (environment.name !== 'dev' && typeof _learnq !== 'undefined' && _learnq) {
      this._userService.getCurrentUser().subscribe(user => {
        _learnq.push(['identify', {
          '$email': user.emailAddress,
          '$first_name': user.firstName,
          '$last_name': user.lastName,
          '$active': user.active,
          '$organization': user.organization.name,
          'developer': user.organization.developer,
          'financier': user.organization.financier,
          'vendor': user.organization.vendor
        }]);
      });
    }
  }
}
