import { environment } from '@environments/environment';

export class OrganizationApiConstants {
  public static organizations = environment.serverUri + `api/${environment.apiVersion}/organizations`;

  public static information = {
    get: () => {
      return `${OrganizationApiConstants.organizations}/null`;
    },
    update: (organizationId: string) => {
      return `${OrganizationApiConstants.organizations}/${organizationId}`;
    }

  };
}
