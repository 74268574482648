import { Injectable } from '@angular/core';
import { Organization } from '@shared/models/organization/organization.model';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataProvider } from 'src/app/analytics/data-integrations/shared/data-provider.enum';
import { OrganizationApiConstants } from './organization.constant';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public getMyOrganization(): Observable<Organization> {
    return this.get(OrganizationApiConstants.information.get())
      .pipe(
        map(result => new Organization(result)
      ));
  }

  public getPrivateProviders(): Observable<DataProvider[]> {
    return this.get<Organization>(OrganizationApiConstants.information.get())
      .pipe(
        map(result => (new Organization(result)).organizationBootstrapProperties.privateDataProviders || [])
      );
  }

  public save(organization: Organization) {
    return this.put(OrganizationApiConstants.information.update(organization.id), JSON.stringify(organization))
    .pipe(
      map(result => new Organization(result)
    ));
  }
}
